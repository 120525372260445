import React from "react"
import style from "./stockists.module.scss"
import LayoutNew from "../../components/layoutNew"
import Map from "../../components/static/pageComponents/stockists/map"

const StockistPage = () => {
  return (
    <LayoutNew>
      <div className={style.container}>
        <div className={style.introduction}>
          <h1>Stockists</h1>
          <p>Find your nearest Phresh stockist below.</p>
        </div>
        <Map />
      </div>
    </LayoutNew>
  )
}

export default StockistPage
