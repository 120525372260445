import React, { useState, useEffect, createContext } from "react"
import style from "./map.module.scss"
import stockistQuery from "../../../queries/allStockists"
import GoogleMapReact from "google-map-react"
import CustomMarker from "./customMarker"
import Search from "./search"

export const MapContext = createContext(null)

const Map = () => {
  const stockists = stockistQuery()

  const [activeStockistIndex, setActiveStockistIndex] = useState(null)
  const activeStockist = stockists[activeStockistIndex]
  const [mapReady, setMapReady] = useState(false)
  const defaultCenter = { lat: 54.082977, lng: -4.998005 }
  const [center, setCenter] = useState(defaultCenter)
  const [zoom, setZoom] = useState(6)

  const context = {
    activeStockistIndex: activeStockistIndex,
    setActiveStockistIndex: setActiveStockistIndex,
  }

  useEffect(() => {
    activeStockistIndex && setCenter(activeStockist.coordinates)
  }, [activeStockistIndex])

  useEffect(() => {
    center !== defaultCenter && setZoom(10)
    zoom === 10 && setZoom(null)
    zoom === null && setZoom(10)
  }, [center])

  return (
    <>
      <div className={style.container}>
        <MapContext.Provider value={context}>
          <GoogleMapReact
            defaultCenter={center}
            center={center}
            zoom={zoom}
            defaultZoom={6}
            onGoogleApiLoaded={() => setMapReady(true)}
            key="stockist_map"
            bootstrapURLKeys={{
              key: process.env.GATSBY_GOOGLE_KEY,
              language: "en",
            }}
          >
            {mapReady &&
              stockists.map(stockist => (
                <CustomMarker
                  lat={stockist.coordinates.lat}
                  lng={stockist.coordinates.lng}
                  key={`stockist_${stockist.index}`}
                  name={stockist.name}
                  index={stockist.index}
                  location={stockist.name}
                  website={stockist.website}
                  phone={stockist.phone}
                />
              ))}
          </GoogleMapReact>
        </MapContext.Provider>
      </div>

      <Search setCenter={setCenter} />
    </>
  )
}

export default Map
