import React, { useState, useRef } from "react"
import style from "./search.module.scss"
import ValidationText from "../../../../../utilities/validationText"

const Search = ({ setCenter }) => {
  const [postcode, setPostcode] = useState("")
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const postcodeToLatLong = () => {
    fetch(`https://api.postcodes.io/postcodes/${postcode}`)
      .then(response => response.json())
      .then(data => {
        setShowErrorMessage(false)
        inputRef.current.blur()
        const coordinates = {
          lat: data.result.latitude,
          lng: data.result.longitude,
        }
        setCenter(coordinates)
      })
      .catch(error => {
        setShowErrorMessage(true)
        console.log(error)
      })
  }

  const handleSubmit = event => {
    event.preventDefault()
    postcodeToLatLong()
  }

  const handleChange = event => {
    setPostcode(event.currentTarget.value)
  }

  const inputRef = useRef()

  return (
    <form className={style.search} onSubmit={handleSubmit}>
      <label>
        <span>Enter your post code:</span>
        <input
          name="search"
          value={postcode}
          placeholder="e.g. SW1A 2AA"
          onChange={handleChange}
          ref={inputRef}
        />
      </label>
      <button onClick={postcodeToLatLong}>Get Results</button>
      <ValidationText
        message="Please enter a complete postcode"
        show={showErrorMessage}
      />
    </form>
  )
}

export default Search
