import React, { useContext } from "react"
import style from "./customMarker.module.scss"
import directionsIcon from "../../../../../../assets/icons/directions-white.svg"
import websiteIcon from "../../../../../../assets/icons/website-white.svg"
import phoneIcon from "../../../../../../assets/icons/phone-white.svg"
import plusIcon from "../../../../../../assets/icons/plus.svg"
import { MapContext } from "../index"

const CustomMarker = ({ name, location, website, phone, index }) => {
  const { activeStockistIndex, setActiveStockistIndex } = useContext(MapContext)

  if (website === "") {
    website = false
  }

  location = location.replace(/\s/g, "+")
  console.log("location :>> ", location)

  const active = index === activeStockistIndex
  return (
    <div className={style.container}>
      <div className={`${style.inner} ${active && style.active}`}>
        <div className={style.name}>{name}</div>
        <button
          className={style.mainIcon}
          onClick={() => setActiveStockistIndex(active ? null : index)}
        >
          <img src={plusIcon} alt="Toggle open/ close icon" />
        </button>

        <div className={style.buttons}>
          {location && (
            <FAB
              href={`https://www.google.com/maps/search/?api=1&query=${location}`}
              alt="Directions icon, link to google maps with directions to chosen location"
              src={directionsIcon}
            />
          )}
          {website && (
            <FAB
              href={website}
              alt="Website icon, links to the stockist website"
              src={websiteIcon}
            />
          )}
          {phone && (
            <FAB
              href={`tel:${phone}`}
              alt="Phone icon, link to stockist contact number"
              src={phoneIcon}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default CustomMarker

const FAB = ({ href, alt, src }) => (
  <a
    className={style.fab}
    href={href}
    target="_blank"
    rel="noreferrer noopener"
  >
    <img alt={alt} src={src} />
  </a>
)
